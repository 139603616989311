<template>
  <div class="premier-wrapper" ref='premierWrapper' v-show="backgroundReady">
      <img :src="backgroundImage" :srcset="`${backgroundImageMobile} 780w,${backgroundImage} 1440w`" sizes='100vw' alt="background_image" class="background-image" @load="backgroundLoaded">
    <div class="premiering-date" :class="{show:title.value}"><span>{{title.name}}</span><span class="reddish">{{title.value}}</span></div> 

      <div class="positional-box"> 
            <div class="arrow nexter" v-if="arrows">
                <img src="@/assets/icons/ico-next.svg" alt="next"  @click="slide(1)">
            </div>
            <div class="arrow previouser" v-if="arrows">    
                <img src="@/assets/icons/ico-prev.svg" alt="previous" @click="slide(-1)">
            </div>
    
        <div class="parent-scroller">   
            <div class="visual-box" :style="{transform:`translate3d(${transform}px,0px,0px)`}" :class="{'transitioning':transition}" ref='slider'>
                <div class="content-wrapper" v-for="content in visualContents" :key="content.key">
                        <div class="image-div">
                            <img :src="content.promourl" alt="no image">
                        </div>
                </div>
            </div>
        </div>
      </div>
      <div class="go-play"><button class="vlt-btn play-button" @click='watchContent'>Watch</button></div>
      <div class="go-home"><button class="no-button home-button" @click='goBack'>Skip</button></div>
  </div>
</template>

<script>
let pickItems=(list,indices)=>{
    return indices.map((index)=>{
                if(index>=list.length)return list[0];
                else if(index<0)return list[list.length-1];
                else return list[index];
    })
}
let monthNames=['January','February','March','April',
                'May','June','July','August',
                'September','October','November','December'];
export default {
 props:['propContents','watchAction','getTitle'],   
 data(){
     return {
         count:0,
         currentIndex:0,
         transition:false,
         transform:'',
         animatingTime:1,
         visualContents:[],
         arrows:false,
         backgroundImage:require('@/assets/images/premiering_background@2x.png'),
         backgroundImageMobile:require('@/assets/images/premiering_background@1x.png'),
         backgroundReady:false
     }
 },
 computed:{
     contents(){
            let contents=this.propContents||[];
            if(contents.length===1){
            //no sliding required.
            contents[0].key=contents[0].contentid;
            return [...contents];    
            }
            //add keys to contents
            let key=0, allcontents=[];
            do{
                allcontents.push(...contents.map(content=>({...content,key:content.contentid+key})));
                key++;
            } 
            while(allcontents.length<3 && contents.length)  
        
           return allcontents;
     },
     title(){
         let currentcontent=this.contents[this.currentIndex];
         if(this.getTitle && (this.getTitle instanceof Function)){
             return this.getTitle(currentcontent);
         }   
         else return {};
     }
 },
 watch:{
    contents(){
        if(this.contents.length>1)this.arrows=true;
        this.getVisualContents();
    } 
 },
 methods:{
    getVisualContents(setter=0){
        let index=0;
        switch(true){
            case setter<0:
            index=this.contents.length-1;
            break;
            case setter>=this.contents.length:
            index=0;
            break;
            default:
            index=setter;
        }

        this.currentIndex=index;
        this.visualContents=pickItems(this.contents,[index-1,index,index+1])
    },
    slide(direction){
        if(this.transition)return;
        this.count+=direction;
        this.animate(true);
        this.transformSlider();
        this.clearTransforms(direction);
    },
    transformSlider(){
        let contentWrapper=this.$refs.premierWrapper.querySelector('.content-wrapper');     
        if(!contentWrapper)return;
        let width=contentWrapper.clientWidth;
        let slider=this.$refs.slider;
        if(!slider)return;
        this.transform=-(this.count*width);
    },
    animate(value){
        this.transition=value;
    },
    clearTransforms(direction){
        this.$nextTick(()=>{
            setTimeout(()=>{
                this.animate(false);
                this.count=0;
                this.getVisualContents(this.currentIndex+direction)              
                this.transformSlider();
            },1000)
        })
    },
    goBack(){
        this.$router.back();
    },
    watchContent(){
        let contentid=this.contents[this.currentIndex] && this.contents[this.currentIndex].contentid;
        if(!contentid)return;

        if(this.watchAction && (this.watchAction instanceof Function)){
            this.watchAction({contentid});
        }
        else{
            this.$router.push({name:'Home'});
            this.$router.push({
            name:'Home/Card',
            params:{contentid}
            });
        }
    },
    backgroundLoaded(){
        this.backgroundReady=true;
    }

 }
}
</script>

<style lang="scss" scoped>
@import '~sass/modules/variables';
@import '~sass/modules/fonts';
@import '~sass/modules/mixins';
@import '~sass/modules/hovereffects';

@mixin visual-width(){
    width:80vw;
    max-width:100vh;
    max-width: calc(140vh - 8.7rem);
}
.no-button{
    background:none;
    outline:none;
    border:0;
}
.premier-wrapper{
    @include flexit($flow:column nowrap);
    height:calc(100vh - #{$header-total-height});
    width:100vw;
    position:fixed;
    overflow:hidden;
    top:$header-total-height;
    left:0;
    z-index:2;
    font-family: $font-family;

    .background-image{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width:100%;
            z-index: -3;
    }

    .premiering-date{
            @include flexit;
            height:4rem;
            width:100%;
            gap:10px;
            span{
                line-height: 2rem;  
                font-size:1.5rem;
                text-align: center;
                text-transform: uppercase;
                font-family: $font-family;
                font-weight: $font-weight-bold;
                color:$font-clr-white;
                &.reddish{
                   color:$font-clr-red;
                }
            }
            visibility: hidden;
            &.show{
                visibility: visible;
            }
    }

    .positional-box{
        @include visual-width;
        position:relative;
        height:max-content;
         .arrow{
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                margin:0 10px;
                width:20px;
                width:clamp(15px,1.5vw,24px);
                cursor:pointer;
                transition:transform 0.1s;
                &:hover{
                    transform:translateY(-50%) scale(1.1);
                }
                &:active{
                    transform:translateY(-50%) scale(1);
                }
                img{
                    display:block;
                    width:100%;
                }
                &.nexter{
                    left:100%;
                }
                &.previouser{
                    right:100%;
                }
            }
        
        .parent-scroller{
            position:relative;
            width:100%;
            height:0;
            padding:28.125% 0;
            overflow:hidden;
            border-radius:5px;
            .visual-box{
            @include flexit();    
            height:100%;
            width:max-content;  
            position:absolute;
            top:0;
            left:0;
            margin-left: -100%;  
            &.transitioning{
                transition:transform 1s ease 0s;
            }
                .content-wrapper{
                    @include visual-width;
                    height:100%;
                    .image-div{
                        height:100%;
                        width:100%;
                        img{
                            height:100%;
                            width:100%;
                        }
                    }
                }
            }
        }
    }
    .go-play{
        margin-top:1.5rem;
        .play-button{
        padding:0.5rem 2rem;
        font-size:1.2rem;
        font-weight: $font-weight-regular;
        cursor:pointer;
        @include buttonHover;
        }
    }
    .go-home{
        margin-top:0.5rem;
        text-align:center;
        .home-button{
            font-size:1rem;
            line-height:1.5rem;
            font-weight: $font-weight-regular;
            color:$font-clr-gray;
            cursor:pointer;
            transition:letter-spacing 0.1s ease 0s;
            &:hover{
                letter-spacing:0.5px;
            }
        }
        
    }
}
</style>